<template>
  <v-card class="cardAdmin mb-4 mx-0" elevation="0">
    <v-container>
      <div class="d-flex">
        <div class="flex-grow-1 flex-shrink-0">
          <div class="d-flex align-center py-2">
            <slot name="avatar" />
            <slot name="content" />
          </div>
        </div>
        <div v-if="showClose" class="flex-grow-0 flex-shrink-0">
          <div @click="deleteAction" class="d-flex align-lg-start justify-end">
            <i class="ri-close-line cardClose"></i>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed v-if="true" color="primary" outlined>
                {{ $t("action.edit") }}
              </v-btn>
            </template>
            <v-list>
              <template v-for="(item, index) in memberRoles">
                <v-list-item v-if="memberListShow(item)" :key="index" @click="selectRole(item)">
                  <v-list-item-title>{{ $t(item) }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

        </div>
      </div>
    </v-container>
    <v-dialog v-if="member" v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          是否確認更改【{{ member.name }}】為 {{ $t(selectedRole) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="dialog = false">
            取消
          </v-btn>
          <v-btn color="primary" @click="send()">
            確認
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    main: {
      type: Boolean,
      default: false,
    },
    control: {
      type: Boolean,
      default: false,
    },
    deleteAction: {
      type: Function,
      default: () => { }
    },
    changeRoleAction: {
      type: Function,
      default: () => { }
    },
    member: Object,
    memberRoles: Array,
    memberRole: String,
  },
  data() {
    return {
      dialog: false,
      selectedRole: ''
    }
  },
  computed: {
    showClose() {
      if (!this.control) return false
      if (this.main) return false
      if (this.memberRole == 'main_manager') return false
      return true
    },
  },
  methods: {
    memberListShow(role) {
      let res = false
      if (role != 'main_manager' && ((this.member.roles.length == 0 && role != 'co_manager') || (this.member.roles.length != 0 && !this.member.roles.includes(role)))) {
        res = true
      }
      return res
    },
    selectRole(item) {
      this.selectedRole = item
      this.dialog = true
    },
    send() {
      this.dialog = false
      this.changeRoleAction(this.selectedRole)
      this.selectedRole = ''
    }
  }
};
</script>
<style lang="sass">
.cardAdmin
  border: 1px solid #cdcdcd !important
  .cardClose
    font-size: 16px
</style>
